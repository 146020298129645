@font-face {
  font-family: 'LEMON-MILK';
  src: url(../public/assets/fonts/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: 'Century-Gothic';
  src: url(../public/assets/fonts/CenturyGothic.ttf);
}

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  font-family: 'Century-Gothic';
  background-color: #000;
  overflow-x: hidden;
  color: #fff;

}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.bg-black{
  background-color: #00000076 !important;
  backdrop-filter: blur(4px);
}

.fs-14 {
  font-size: 14px !important;
}

p {
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 43px
}

/* Header  */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  /* background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(10px); */
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link img {
  height: 22px;
  transition: all .2s ease-in-out;

}

.navbar .nav-link:hover img{
  transform: translate(0px, -10px);
  transition: all .2s ease-in-out;
}


.navbar-nav {
  position: relative;
}

/* .navbar-nav::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -5px;
  left: 0;
  position: absolute;
} */

.navbar-collapse.collapsing {
  transition: none !important;
}

/* Banner */

.banner {
  padding-top: 180px;
  padding-bottom: 100px;
  display: flex;
  align-items: center;
  background-image: url(../public/assets/bg1.png);
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;

}

.banner h1 {
  color: #FFF;
  font-family: "LEMON-MILK";
  font-size: 32px;
  font-weight: 400;
}

h2 {
  color: #FFF;
  font-size: 36px;
  font-weight: 400;
  position: relative;
}

.banner h2::after {
  content: "";
  background: #2AE8DC;
  height: 2px;
  width: 100%;
  bottom: -15px;
  left: -60px;
  position: absolute;
}

.zeta {
  height: 45px;
}

.social-icons p {
  max-width: 650px;
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 36px
}


.primary-btn {
  font-weight: 400;
  font-size: 18px;
  color: #000;
  border-radius: 12px;
  font-family: "LEMON-MILK";
  background: linear-gradient(90deg, #2AE8DC 0%, #1091F7 48.35%, #E969BC 99.82%);
  height: 50px;
  padding: 12px 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: linear-gradient(180deg, #2AE8DC 0%, #1091F7 48.35%, #E969BC 99.82%);
  transition: all .3s ease-in-out;
  color: #000;

}

.float-btn .primary-btn img {
  transition: all .3s ease-in-out;
}

.float-btn .primary-btn:hover img {
  transform: translate(5px, -5px);
  transition: all .3s ease-in-out;
}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}

@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .nav-link {
    color: white !important;
  }

  .banner {
    position: relative;
    z-index: 111;
    align-items: flex-start;
    height: auto;
    padding-top: 100px;
    background-size: 70%;
    background-position: top right;
    padding-bottom: 50px;
    flex-direction: column;
  }


  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 100%;
    left: 0;
    height: 100vh;
    background: black;
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }

  .navbar-nav {
    gap: 20px;
    flex-direction: row;
    margin-top: 25px;
}

  .navbar-nav::after {
    content: none;
  }

   .zeta {
    height: 30px;
  }

}

.table-responsive{
  overflow-x: hidden;
}

@media(max-width: 767px) {
  .table-responsive{
    overflow-x: auto;
  }
  .w-850{
    width: 850px;
  }
  .w-800 .col-50{
    width: 100%;
    flex: auto;
  }
  .sm-btn {
    position: static;
    margin-top: 50px;
  }

  .banner h1 {
    font-size: 26px;
    margin-right: 10px;
  }

  h2 {
    font-size: 32px;
  }

  

  .banner h2::after {
    bottom: -15px;
  }

  .social-icons {
    position: static;
  }

  .social-icons p {
    font-size: 20px;
    line-height: 1.6;
  }

  .primary-btn {
    font-size: 16px;
    height: 48px;
    padding: 10px 25px;
  }

  .float-btn {
    position: static;
  }

}


.zen-card {
  border-radius: 30px;
  border: 2px solid #2AE8DC;
  height: 100%;
}
.zen-img{
  max-width: 350px;
  height: 150px;
}
.zen-img img{
  border-radius: 30px;
  height: 100%;
  object-fit: contain;
  object-position: top left;
}
.zen-detail{
  padding: 0 25px 25px 25px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: space-between;
  height: 1005;

}

.zen-card h4 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.zen-card ul{
  padding-left: 20px;
}

.zen-card ul li{
  list-style-type: disc;
  margin-bottom: 20px;
  color: #FFF;
  font-size: 24px;
  font-weight: 400;
  line-height: 37px
}
.zen-card ul li:last-child{
  margin-bottom: 0px;
}

.zen-ball {
  border-radius: 280px;
  width: 280px;
height: 280px;
  border: 2px solid #2AE8DC;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zen-ball p {
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 0;
}

.bottom-bg {
  padding-top: 100px;
  padding-bottom: 30px;
  background-image: url(../public/assets/bg2.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

footer p{
  font-size: 20px;
  line-height: 30px;
}



@media (max-width: 767px){
  .no-wrap{
    flex-wrap: nowrap;
  }
  .sm-bg{
    position: relative;
    margin-top: -160px;
    z-index: -1;
  }

  .zen-ball{
    height: 180px;
    width: 180px;
  }
  .zen-ball p{
    font-size: 16px;
    line-height: 26px;
  }
  .bottom-bg{
    padding-bottom: 50px;
    padding-top: 00px;
  }
  .zen-card ul li {
    color: #FFF;
    font-size: 20px;
    line-height: 32px;
}
.zen-detail{
  gap: 20px;
}
.zen-card h4{
  font-size: 24px;
}
p {
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}
}